<div class="container mt-3">
    <h3 class="mb-3">Nyék Soft Tervező Kft.</h3>
    <p>
        Vállalkozásunk 2000. óta működik a közutak tervezésének piacán. Cégünk vezetője kultúrmérnökként 1996 óta
        dolgozott több - országosan is ismert - tervezőcégnél, ahol széleskörű rálátást szerzett a fő tevékenység piacára.
        Részt vett nagy volumenű projektek megtervezésében, amelyekből komoly tapasztalatot gyűjtött. Megszerezve aláírási
        jogkörét megalapította a Nyék Terv Mérnökiroda Bt. (névváltoztatás történt Nyitrai és Kőszegi Bt.-ről), amelynek
        fő tevékenysége a vonalas létesítmények tervezése. A megbízóink köre dinamikusan fejlődik, amelyet leginkább az
        mutat meg, hogy az alapítás évében az árbevételünk 6 m. Ft volt, 2005-ben viszont elértük a 25 millió Ft-ot.
        Célunk, hogy partnereink szemében a megbízható, gyors SZOLGÁLTATÓ legyünk, amely hosszútávon kielégíti Megbízóinak
        közlekedéstervezéssel kapcsolatos igényeit. Nyitottak vagyunk a piac változásaira és ügyfeleink szükségleteire.
        Rugalmasan reagálunk kihívásokra, kiemelkedő szervezési készségünkkel gyorsan tudunk eredményeket felmutatni az
        infrastrukturális tervezés széles spektrumán.
    </p>
    <br />
    <br />
    <h4>Főbb Tevékenységeink:</h4>
    <ul>
        <li>Közutak tervezése (komplett feladat bonyolítással)</li>
        <li>Közúti csomópontok tervezése</li>
        <li>Közúti és vasúti csomópontok tervezése</li>
        <li>Kerékpárutak tervezése</li>
        <li>Mérnöki tanácsadás, szakértés</li>
        <li>Tervezői művezetés</li>
    </ul>
    <p>
        A vállalkozásunk megalapítására 2005 év végén került sor. Fő profilunk szoftverek kis- és nagykereskedelme. Cégünk
        vezetője infrastruktúrális mérnökként szembesült a kultúrmérnöki programok hiányosságaival. Megfogalmazódott benne
        a gondolat, hogy egy könnyen kezelhető és széleskörű feladatokat ellátni képes programot kell a piacnak
        biztosítani.
    </p>
    <p>
        Tervezési feladatai során találkozott egy nemzetközileg ismert és elismert kultúrmérnöki programmal, amely
        megoldást jelentett a mindennapi tervezési feladatok problémáira.
    </p>
    <p>
        Kizárólagos jogot megszerezve Magyarországon egyedüli nagykereskedőként a Nyék Soft Tervező Kft. láthatja el
        partnereit az ALIHO elnevezésű építőmérnöki tervezést segítő programmal.
    </p>
    <p>
        A program széles spektrumát tudja ellátni a közlekedéstervezési feladatoknak. Könnyen hozzáférhető, és alacsony
        árával minden egyéb ezirányú programnál költséghatékonyabb. Gyors és szakszerű tanácsadással kiegészítve
        kereskedelmi tevékenységünket vevőink 100 %-os elégedettségét értük el.
    </p>
</div>