<div class="container mt-3">
    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-8">
            <div lang="hu-HU" link="#000080" vlink="#800000" dir="ltr">
                <p style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">Kedvezm&eacute;nyezett
                            neve: NY&Eacute;K SOFT Tervez&#337;, Kereskedelmi &eacute;s
                            Szolg&aacute;ltat&oacute; Kft.</font>
                    </font>
                </p>
                <p style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">Kedvezm&eacute;nyezett
                            c&iacute;me: 9023 Gy&#337;r, Feh&eacute;rv&aacute;ri &uacute;t 28. A.
                            &eacute;p.</font>
                    </font>
                </p>
                <p align="justify" style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">P&aacute;ly&aacute;zati
                            konstrukci&oacute;:</font>
                    </font>
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">
                        </font>
                    </font>
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">H&aacute;tr&aacute;nyos
                            helyzet&#369; telep&uuml;l&eacute;seken m&#369;k&ouml;d&#337; mikro-
                            &eacute;s kisv&aacute;llalkoz&aacute;sok fejleszt&eacute;seinek
                            t&aacute;mogat&aacute;sa</font>
                    </font>
                </p>
                <p align="justify" style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">Projekt
                            c&iacute;me: NY&Eacute;K SOFT Tervez&#337;, Kereskedelmi &eacute;s
                            Szolg&aacute;ltat&oacute; Kft. g&eacute;pbeszerz&eacute;se &eacute;s
                            b&eacute;rt&aacute;mogat&aacute;sa</font>
                    </font>
                </p>
                <p align="justify" style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">Projekt
                            azonos&iacute;t&oacute;sz&aacute;m: GINOP-1.2.9-20-2021-06569</font>
                    </font>
                </p>
                <p align="justify" style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">Elnyert
                            t&aacute;mogat&aacute;s:</font>
                    </font>
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">
                        </font>
                    </font>
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">9
                            948 820,-Ft</font>
                    </font>
                </p>
                <p align="justify" style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">T&aacute;mogat&aacute;s
                            intenzit&aacute;sa: 70%</font>
                    </font>
                </p>
                <p align="justify" style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">Projekt
                            fizikai befejez&eacute;s&eacute;nek tervezett napja: 2022.12.31.</font>
                    </font>
                </p>
                <p align="justify" style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">Finansz&iacute;roz&oacute;
                            alap: ERFA</font>
                    </font>
                </p>
                <p align="justify" style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">Projekt
                            c&eacute;lja &eacute;s tartalma:</font>
                    </font>
                </p>
                <p align="justify" style="line-height: 115%; margin-bottom: 0.35cm">
                    <font face="Times New Roman, serif">
                        <font size="3" style="font-size: 12pt">T&aacute;rsas&aacute;gunk
                            a NY&Eacute;K SOFT Tervez&#337;, Kereskedelmi &eacute;s Szolg&aacute;ltat&oacute;
                            Kft. 2005-&ouml;s alap&iacute;t&aacute;sa &oacute;ta (a 9023 Gy&#337;r,
                            Feh&eacute;rv&aacute;ri &uacute;t 28. A. &eacute;p. alatti
                            sz&eacute;khely&eacute;n) v&eacute;gzi folyamatosan tev&eacute;kenys&eacute;g&eacute;t.
                            Csatolt c&eacute;gb&iacute;r&oacute;s&aacute;gi bejegyz&eacute;s&uuml;nknek
                            megfelel&#337;en f&#337;tev&eacute;kenys&eacute;g&uuml;nk a m&eacute;rn&ouml;ki
                            tev&eacute;kenys&eacute;g, m&#369;szaki tan&aacute;csad&aacute;si
                            tev&eacute;kenys&eacute;g (t&ouml;bbek k&ouml;z&ouml;tt: k&ouml;zutak
                            tervez&eacute;s&eacute;hez, k&ouml;z&uacute;ti csom&oacute;pontok
                            tervez&eacute;s&eacute;hez &eacute;s ker&eacute;kp&aacute;rutak
                            tervez&eacute;s&eacute;hez kapcsol&oacute;d&oacute;an). Terveink
                            szerint a p&aacute;ly&aacute;zati forr&aacute;s felhaszn&aacute;l&aacute;s&aacute;val
                            jelenlegi telephely&uuml;nk&ouml;n egy egyr&eacute;szt a
                            f&#337;tev&eacute;kenys&eacute;g&uuml;nk kiszolg&aacute;l&aacute;s&aacute;t
                            is biztos&iacute;t&oacute; b&eacute;rt&aacute;mogat&aacute;st,
                            m&aacute;sr&eacute;szt ehhez kapcsol&oacute;d&oacute;an a fejleszt&eacute;si
                            alapj&aacute;t jelent&#337; min&#337;s&eacute;gi, magas sz&iacute;nvonal&uacute;
                            dinamikus szonda eszk&ouml;zt &eacute;s tartoz&eacute;kait szeretn&eacute;nk
                            beszerezni. Ez az eszk&ouml;zbeszerz&eacute;s hozz&aacute;j&aacute;rulna
                            a magas sz&iacute;nvonal&uacute; m&eacute;rn&ouml;ki tev&eacute;kenys&eacute;g
                            felvir&aacute;goztat&aacute;s&aacute;hoz. Tev&eacute;kenys&eacute;g&uuml;nk
                            megb&iacute;zhat&oacute;bb&aacute;, k&ouml;lts&eacute;gtakar&eacute;koss&aacute;
                            v&aacute;lik. A magas min&#337;s&eacute;g&#369;, kiemelked&#337;
                            param&eacute;terekkel rendelkez&#337; m&#369;szer beszerz&eacute;se
                            &aacute;ltal k&ouml;lts&eacute;gmegtakar&iacute;t&aacute;st tudunk
                            el&eacute;rni, felszabadul&oacute; p&eacute;nzeszk&ouml;zeinket &uacute;jabb
                            fejleszt&eacute;sekre ford&iacute;thatjuk. Rem&eacute;nyeink szerint
                            a fejleszteni k&iacute;v&aacute;nt tev&eacute;kenys&eacute;gi k&ouml;r
                            adta biztons&aacute;gosabb j&ouml;vedelemtermel&#337; k&eacute;pess&eacute;g
                            el&#337;seg&iacute;ti a v&aacute;llalkoz&aacute;s m&eacute;g
                            stabilabb m&#369;k&ouml;d&eacute;s&eacute;t. A projekt c&eacute;lja
                            egy fenntarthat&oacute;bb v&aacute;llalkoz&aacute;s hossz&uacute;
                            t&aacute;v&uacute; m&#369;k&ouml;dtet&eacute;se, mely magas
                            sz&iacute;nvonal&uacute; szolg&aacute;ltat&aacute;st ny&uacute;jt az
                            eml&iacute;tett tev&eacute;kenys&eacute;gi k&ouml;rben.
                            F&#337;tev&eacute;kenys&eacute;g&uuml;nk k&ouml;lts&eacute;ghat&eacute;konyabb
                            lehet, mivel saj&aacute;t eszk&ouml;zzel tudjuk megval&oacute;s&iacute;tani
                            a geotechnikai felhaszn&aacute;l&aacute;s, talajfelt&aacute;r&aacute;s
                            &eacute;s mintav&eacute;telez&eacute;i tev&eacute;kenys&eacute;geket.
                            Eddigi tev&eacute;kenys&eacute;g&uuml;nk megb&iacute;zhat&oacute;bb&aacute;,
                            k&ouml;lts&eacute;gtakar&eacute;koss&aacute; v&aacute;lik az&aacute;ltal
                            is, hogy a rendelkez&eacute;sre &aacute;ll&oacute; eszk&ouml;z is
                            hasznosulhat tev&eacute;kenys&eacute;g&uuml;nk promot&aacute;l&aacute;sa
                            sor&aacute;n. Tervezett fejleszt&eacute;s&uuml;nkh&ouml;z saj&aacute;t
                            forr&aacute;saink nem elegend&#337;ek egy ilyen ar&aacute;ny&uacute;
                            fejleszt&eacute;shez, csak p&aacute;ly&aacute;zati keretek k&ouml;z&ouml;tt
                            tudjuk elk&eacute;pzelni ezen eszk&ouml;z beszerz&eacute;s&eacute;t.
                            A fejlesztend&#337; tev&eacute;kenys&eacute;g v&eacute;gz&eacute;s&eacute;hez
                            egy&eacute;b k&eacute;pzetts&eacute;g/v&eacute;gzetts&eacute;g nem
                            sz&uuml;ks&eacute;ges. A fejleszt&eacute;s megval&oacute;sul&aacute;sa
                            eset&eacute;n az &aacute;ltalunk ny&uacute;jtott szolg&aacute;ltat&aacute;s
                            magas sz&iacute;nvonal&uacute;, megb&iacute;zhat&oacute; &eacute;s
                            kisz&aacute;m&iacute;that&oacute; lesz. C&eacute;g&uuml;nk m&#369;k&ouml;d&eacute;se
                            stabilabb lesz, tov&aacute;bbi fejl&#337;d&eacute;sre, illetve
                            fejleszt&eacute;sre ad alapot, V&aacute;llalkoz&aacute;sunk
                            versenyk&eacute;pess&eacute;ge er&#337;s&ouml;dik, tov&aacute;bbi
                            n&ouml;veked&eacute;s alapj&aacute;t teremti meg. Helyi v&aacute;llalkoz&aacute;si
                            potenci&aacute;l n&ouml;vekszik. Tervezett p&aacute;ly&aacute;zatunk
                            a GINOP program c&eacute;lkit&#369;z&eacute;s&eacute;vel teljes
                            &ouml;sszhangban van, a helyi gazdas&aacute;g t&eacute;rnyer&eacute;s&eacute;t
                            seg&iacute;ti el&#337;. Tervezetten az elm&uacute;lt k&eacute;t &eacute;v
                            &aacute;tlag&aacute;rbev&eacute;tel&eacute;nek n&ouml;veked&eacute;se
                            minimum 5%-os lesz. Tervezett fejleszt&eacute;s&uuml;nk a helyi
                            adotts&aacute;gokra &eacute;p&uuml;l, fenntarthat&oacute;,
                            megalapozott piacelemz&eacute;ssel al&aacute;t&aacute;masztott,
                            alaposan &aacute;tgondolt, k&ouml;lts&eacute;gtakar&eacute;kos
                            gazdas&aacute;gfejleszt&eacute;si elk&eacute;pzel&eacute;st
                            tartalmaz, a vid&eacute;ki &eacute;let h&aacute;tr&aacute;nyainak
                            cs&ouml;kkent&eacute;se, a foglalkoztat&aacute;s &eacute;s az
                            &eacute;letmin&#337;s&eacute;g jav&iacute;t&aacute;sa biztos&iacute;tott.
                            A p&aacute;ly&aacute;zatban ig&eacute;nybe venni k&iacute;v&aacute;nt
                            b&eacute;rt&aacute;mogat&aacute;s sokat seg&iacute;thet a
                            k&ouml;lts&eacute;ghat&eacute;konys&aacute;gi oldalon: az elnyert
                            b&eacute;rt&aacute;mogat&aacute;s r&eacute;v&eacute;n tov&aacute;bbi
                            fejleszt&eacute;sek el&#337;tt ny&iacute;lhat meg forr&aacute;s. Az
                            &uuml;zletvitelhez sz&uuml;ks&eacute;ges, valamint az &uuml;zemeltet&eacute;s&eacute;hez
                            sz&uuml;ks&eacute;ges hum&aacute;n er&#337;forr&aacute;s az &uuml;gyvezet&#337;
                            &eacute;s alkalmazottak szem&eacute;ly&eacute;ben rendelkez&eacute;sre
                            &aacute;ll. A v&aacute;llalkoz&aacute;s els&#337;dleges, c&eacute;lzott
                            megval&oacute;s&iacute;t&aacute;si ter&uuml;lete, fejlesztett
                            szolg&aacute;ltat&aacute;sunk ig&eacute;nybe vev&#337;i k&ouml;re
                            Gy&#337;r-Moson-Sopron megye eg&eacute;sz ter&uuml;lete.</font>
                    </font>
                </p>
            </div>
        </div>
    </div>


</div>