import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutPageComponent } from './about-page/about-page.component';
import { CompetitionComponent } from './competition-page/competition-page.component';
import { ContactsPageComponent } from './contacts-page/contacts-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ReferencesPageComponent } from './references-page/references-page.component';
import { SoftwaresPageComponent } from './softwares-page/softwares-page.component';

const routes: Routes = [
  { path: "home", component: HomePageComponent },
  { path: "about", component: AboutPageComponent },
  { path: "softwares", component: SoftwaresPageComponent },
  { path: "references", component: ReferencesPageComponent },
  { path: "contacts", component: ContactsPageComponent },
  { path: "palyazat/gepbeszerzese-es-bertamogatasa", component: CompetitionComponent },
  { path: "**", component: HomePageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
