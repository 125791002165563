<div class="container">
    <div class="d-flex justify-content-center">
        <div class="mt-3">
            <div class="card-deck">
                <div class="card">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                            <img class="card-img-top" src="assets/images/aliho_doboz.png" alt="Card image cap" />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">ALIHO kultúrmérnöki tervező program</h5>
                                <p class="card-text">
                                    Út,- Vasút és nyomvonalas mérnöki létesítmények tervezése. Terepmodell készítés, helyszínrajz-,
                                    hossz-szelvény szerkesztés és kirajzolás. Keresztszelvények rajzolása, földtömegszámítás és
                                    burkolati mennyiségek számítása.Perspektivikus képek előállítása prezentációs anyagokhoz. Komplett 3
                                    dimenziós vonalas létesítmény tervezés
                                </p>
                            </div>
                            <h6>Nettó listaár: 325.000,- Ft</h6>
                            <a class="btn btn-info btn-block" href="contacts">Kapcsolatfelvétel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>