<div>
    <video preload="auto" muted loop class="myVideo" autoplay oncanplay="this.play()"
        onloadedmetadata="this.muted = true">
        <source src="assets/videos/backgroundvideo.mp4" type="video/mp4" />
    </video>
    <div class="jumbotron jumbotron-fluid" id="box">
        <div class="container">
            <h1 id="text">Üdvözöljük weboldalunkon!</h1>
            <p>
                Örömmel tudatjuk, hogy cégünk megkezdte az intelliCAD tervezőprogram alapokon futó ALIHO kultúrmérnöki
                programcsomag hazai forgalmazását.
            </p>
            <a class="btn btn-primary" href="softwares">Tovább a szoftverekhez</a>
            <a class="btn btn-primary ml-2" [routerLink]="'/palyazat/gepbeszerzese-es-bertamogatasa'">
                Pályázatok
            </a>
        </div>
    </div>
</div>