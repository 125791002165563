import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  templateUrl: './references-page.component.html',
  styleUrls: ['./references-page.component.scss']
})
export class ReferencesPageComponent implements OnInit {
  datas: datas[];
  modalItem: datas;

  constructor(private http: HttpClient, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.http.get('assets/datas.json').subscribe((resoult: datas[]) => {
      this.datas = resoult;
    })
  }

  openXl(content, item: datas) {
    this.modalItem = item;
    this.modalService.open(content, { size: 'xl' });
  }
}

export interface datas {
  title: string;
  fileSmall: string;
  fileBig: string;
}