import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutPageComponent } from './about-page/about-page.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactsPageComponent } from './contacts-page/contacts-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ReferencesPageComponent } from './references-page/references-page.component';
import { SoftwaresPageComponent } from './softwares-page/softwares-page.component';


@NgModule({
  declarations: [
    AboutPageComponent,
    AppComponent,
    ContactsPageComponent,
    HomePageComponent,
    ReferencesPageComponent,
    SoftwaresPageComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    NgbModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
