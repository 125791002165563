<div class="container mt-4">
    <div class="card-columns">
        <div *ngFor="let item of datas" class="card">
            <img [src]="'assets/images/references/' + item.fileSmall" class="card-img" alt="..." loading="lazy" (click)="openXl(content, item)">
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-body" (click)="modal.close('Close click')">
        <img [src]="'assets/images/references/' + modalItem.fileBig" class="w-100 mx-auto">
        <p class="title">{{ modalItem.title }}</p>
    </div>
</ng-template>