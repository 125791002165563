<nav class="navbar navbar-expand-md navbar-dark">
  <a class="navbar-brand" [routerLink]="'home'">Nyék Soft kft.</a>

  <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <!-- Step 2: Add the ngbCollapse directive to the element below. -->
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'home'" routerLinkActive="active" (click)="isMenuCollapsed = true">Kezdőlap</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'about'" routerLinkActive="active" (click)="isMenuCollapsed = true">Rólunk</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'softwares'" routerLinkActive="active" (click)="isMenuCollapsed = true">Szoftver</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'references'" routerLinkActive="active" (click)="isMenuCollapsed = true">Referenciák</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'contacts'" routerLinkActive="active" (click)="isMenuCollapsed = true">Kapcsolatok</a>
      </li>
    </ul>
  </div>
</nav>

<div  class="competition-link" >
  <a [routerLink]="'/palyazat/gepbeszerzese-es-bertamogatasa'" >
    <img src="../assets/images/competition-logo.png" alt="palyazat logo">  
  </a>
</div>

<router-outlet></router-outlet>